<template>
  <!-- Modal -->
  <div class="modal" id="codigoOtpFirmeseModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <form>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Verificación de seguridad</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row text-center">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-md-12 col-form-label">Digite a continuación el código de verificación de Fírmese enviado a su <span v-if="!codigo.email">teléfono <i class="fas fa-mobile-alt"></i></span><span v-else>correo electrónico <i class="far fa-envelope"></i></span></label>
                </div>
              </div>
            </div>
            <div class="row text-center">
              <div class="col-md-12">
                <div class="form-group row">
                  <input maxlength="1" class="codigo-otp-firmese form-control col-md-2" v-model="codigo.char1" autofocus required />
                  <input maxlength="1" class="codigo-otp-firmese form-control col-md-2" v-model="codigo.char2" required />
                  <input maxlength="1" class="codigo-otp-firmese form-control col-md-2" v-model="codigo.char3" required />
                  <input maxlength="1" class="codigo-otp-firmese form-control col-md-2" v-model="codigo.char4" required />
                  <input maxlength="1" class="codigo-otp-firmese form-control col-md-2" v-model="codigo.char5" required />
                  <input maxlength="1" class="codigo-otp-firmese form-control col-md-2" v-model="codigo.char6" required />
                </div>
              </div>
            </div>
            <div v-if="!codigo.email" class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <p class="text-right" style="font-size:14px">
                    No ha recibido el código de verificación?
                    <a href ="#" v-on:click="$emit('cambiar-medio', codigo)"> Envíelo a su correo electrónico</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <button type="submit" class="btn btn-primary" data-dismiss="modal" v-on:click="$emit('add-codigo', codigo)">Aceptar</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
//import { focus } from 'vue-focus';
import moment from 'moment';

export default {
  props: ['codigo'],
  data() {
    return {
      focused: false
    }
  },
  created: function(){
  },
  mounted: function() {
    //this.setFocus();
    $('.codigo-otp-firmese').keyup(function(){
        if($(this).val().length==$(this).attr("maxlength")){
            $(this).next().focus();
        }
    });

    $('.codigo-otp-firmese').keydown(function(e){
      if($(this).val().length==0){
        const key = e.key; // const {key} = event; ES6+
        if (key === "Backspace") {
          var inputs = $('input');
          var index = inputs.index(this);
          if (index != 0)
            inputs.eq(index - 1).val('').focus();
        }
      }
    });

    $('.codigo-otp-firmese').keyup(function(){
      this.value = this.value.toUpperCase();
    });
  },
  methods: {
    setFocus: function() {
      this.$refs.Descripcion.focus();
    }
  }
}
</script>

<style>
/*
.modal-dialog {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width:500px;
    height:300px;
}
*/
</style>
