<template>
  <!-- Modal -->
  <div class="modal" id="indiceModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <form>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ indicesButtonText }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Índice (*):</label>
                  <select v-model="indice.ind_id" class="form-control col-md-8" autofocus required>
                    <option v-for="i in indices" v-bind:value="i._id">
                      {{ i.ind_nmbre }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Valor (*): </label>
                  <textarea rows="3" type="text" class="form-control col-md-8" v-model="indice.ixd_valor">
                  </textarea>
                </div>
            </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <button type="submit" class="btn btn-primary" data-dismiss="modal" v-on:click="$emit('add-indice', indice)">{{ indicesButtonText }}</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
//import { focus } from 'vue-focus';

export default {
  //directives: { focus: focus },
  props: ['indicesButtonText', 'indice'],
  data() {
    return {
      //indice: {}
      indices: [],
      focused: false
    }
  },
  created: function(){
    this.fetchIndice();
  },
  mounted: function() {
    //this.setFocus();
  },
  methods: {
    fetchIndice()
    {
      let uri = '/indices/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          if (a.ind_nmbre > b.ind_nmbre) { return 1; };
          if (a.ind_nmbre < b.ind_nmbre) { return -1; };
          return 0;
        });
        this.indices = list;
      });
    },
    setFocus: function() {
      this.$refs.ind_cdgo.focus();
    }
  }
}
</script>

<style>
/*
.modal-dialog {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width:500px;
    height:300px;
}
*/
</style>
