<template>
  <!-- Modal -->
  <div class="modal" id="seguimientoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <form>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ seguimientosButtonText }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!--
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Id (*):</label>
                  <input type="text" class="form-control col-md-8" v-model="seguimiento.SeguimientoId" disabled="true">
                </div>
              </div>
            </div>
            -->
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Fecha (*):</label>
                  <input type="text" class="form-control col-md-8" v-model="Fecha" disabled="true">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">De (*):</label>
                  <select v-model="seguimiento.DeUsuarioId" class="form-control col-md-8" required disabled="true">
                    <option v-for="i in usuarios" v-bind:value="i._id">
                      {{ i.usu_nmbre + ' ' + i.usu_aplldo + ' (' + i.usu_cdgo + ')' }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Para (*):</label>
                  <select v-model="seguimiento.ParaUsuarioId" class="form-control col-md-8" required>
                    <option v-for="i in usuarios" v-bind:value="i._id">
                      {{ i.usu_nmbre + ' ' + i.usu_aplldo + ' (' + i.usu_cdgo + ')' }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Descripción (*):</label>
                  <textarea rows="3" class="form-control col-md-8" v-model="seguimiento.Descripcion" autofocus required></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Estado (*): </label>
                  <select v-model="seguimiento.EstadoId" class="form-control col-md-8" v-on:change="setEstado" required>
                    <option v-for="i in estados" v-bind:value="i._id">
                      {{ i.Nombre }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row" v-if="showFv">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Fecha de vencimiento:</label>
                  <input type="date" class="form-control col-md-8" v-model="seguimiento.FechaVencimiento">
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <button type="submit" class="btn btn-primary" data-dismiss="modal" v-on:click="$emit('add-seguimiento', seguimiento)">{{ seguimientosButtonText }}</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
//import { focus } from 'vue-focus';
import moment from 'moment';

export default {
  //directives: { focus: focus },
  props: ['seguimientosButtonText', 'seguimiento', 'usuarios', 'estados', 'compartir'],
  data() {
    return {
      //indice: {}
      //usuarios: [],
      //estados: [],
      Fecha: null,
      focused: false,
    }
  },
  computed: {
    showFv(){
      if (this.seguimiento.EstadoId){
        var estado = this.estados.find(i => i._id == this.seguimiento.EstadoId);
        return estado?.Compartir;        
      } else{
        return false;
      }
    }
  },
  created: function(){
    //this.fetchUsuarios();
    //this.fetchEstados();
    this.Fecha = moment(this.seguimiento.Fecha).format('DD/MMM/YYYY hh:mm A');
  },
  mounted: function() {
    //this.setFocus();
  },
  methods: {
    fetchUsuarios()
    {
      var p = {};
      p.compania = this.$store.state.company;
      
      /*
      //alert(JSON.stringify(this.seguimiento));
      if (this.add){
        p.todo = true;
      } else {
        p.todo = false;
      }
      */
      p.todo = true;
          
      let uri = '/usuarios/seguimiento';
      this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
      this.axios.post(uri, p).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          if (a.usu_nmbre > b.usu_nmbre) { return 1; };
          if (a.usu_nmbre < b.usu_nmbre) { return -1; };
          return 0;
        });
        this.usuarios = list;
      });
    },
    fetchEstados(){
      let uri = '/estados/seguimiento';
      this.axios.post(uri, { cmp_id: String(this.$store.state.company), allowModify: this.allowModify })
      .then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          if (a.Nombre > b.Nombre) { return 1; };
          if (a.Nombre < b.Nombre) { return -1; };
          return 0;
        });
        this.estados = list;
      });
    },
    setFocus: function() {
      this.$refs.Descripcion.focus();
    },
    setEstado(e){
      var t = e.target.value;
      this.seguimiento.FechaVencimiento = null;
    }
  }
}
</script>

<style>
/*
.modal-dialog {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width:500px;
    height:300px;
}
*/
</style>
