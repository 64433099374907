<template>
  <div>
    <select class="form-control" :id="id" :name="name" :disabled="disabled" :required="required"></select>
  </div>
</template>

<script>
import $ from 'jquery';
import 'select2/dist/js/select2.full';
import 'select2/dist/css/select2.min.css';
//import 'select2/dist/js/i18n/es';

export default {
  name: 'select2ajax',
  data() {
    return {
      select2: null
    };
  },
  model: {
    event: 'change',
    prop: 'value'
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    settings: {
      type: Object,
      default: () => {}
    },
    value: null,
    addOption: '',
    url: {
      type: String,
      default: ''
    }
  },
  watch: {
    options(val) {
      this.setOption(val);
    },
    value(val) {
      this.setValue(val);
    }
  },
  methods: {
    setOption(val) {
      this.select2.empty();
      this.select2.select2({
        placeholder: this.placeholder,
        settings: this.settings,
        data: val, allowClear: true
      });
      this.setValue(this.value);
    },
    setValue(val) {
      if (val instanceof Array) {
        this.select2.val([...val]);
      } else {
        this.select2.val([val]);
      }
      this.select2.trigger('change');
    },
    closeSelect(){
      this.$emit('close-select');
    }
  },
  mounted() {
    var vm = this;
    this.select2 = $(this.$el)
      .find('select')
      .select2({
        placeholder: this.placeholder,
        settings: this.settings,
        data: this.options, language: 'es',
        minimumInputLength: 3,
        ajax: {
          url: vm.url,
          data: function (params) {
            var query = {
              cmp_id: String(vm.$store.state.company),
              key: params.term
            }

            return query;
          },
          processResults: function(data) {
            // Tranforms the top-level key of the response object from 'items' to 'results'
            var result_data = $.map(data, function(obj) {
              obj.id = obj.id;
              obj.text = obj.text;
              return obj;
            });
            return {
              results: result_data
            };
          }
        },
        templateResult: function(repo) {
          return repo.text;
        },
        templateSelection: function(repo) {
          return repo.text;
        }
      })
      .on('select2:select select2:unselect', ev => {
        this.$emit('change', this.select2.val());
        this.$emit('select', ev['params']['data']);
      })
      .on('select2:open', () => {
        $(".select2-results:not(:has(a))").append(this.addOption);
      });
    this.setValue(this.value);

    var link = this.select2.data('select2');

    link.onSelect = (function (fn) {
        return function (data, options) {
            var target;

            if (options != null) {
                target = $(options.target);
            }

            // In case where the target is your own button, handle it
            if (target && target.hasClass('new-item')) {
                alert("clicked: " + $(target).data("id"));
            } else {
                return fn.apply(this, arguments);
            }
        }
    })(link.onSelect);
  },
  beforeDestroy() {
    this.select2.select2('destroy');
  }
};
</script>
<style>
  .select2-container .select2-selection--single {
      box-sizing: border-box;
      border: 1px solid #ced4da;
      cursor: pointer;
      display: block;
      height: 40px;
      user-select: none;
      -webkit-user-select: none;
  }

  .select2-container--default .select2-selection--single .select2-selection__rendered {
      color: #444;
      line-height: 40px;
  }    

  .new-item {
      color: #00f;
      font-weight: bold;
      font-size: 14px;
      float: right;
      position: relative;
      z-index: 1;
  }

  .new-item:hover {
      text-decoration: underline;
  }  
</style>