<template>
  <!-- Modal -->
  <div class="modal" id="entidadModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <form v-on:submit.prevent="saveItem()">
      <div class="modal-dialog" role="document">
        <div class="modal-content bigModal">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">            
            <div class="text-right text-info">
              <label>(*) Campo obligatorio</label>
            </div>
            <div class="form-group">
              <label>Nombre (*):</label>
              <input type="text" class="form-control" v-model="entidad.ent_nmbre" required autofocus>
            </div>
            <div class="form-group">
              <label>Tipo de identificación, sin dígito de verificación (*):</label>
              <select v-model="entidad.tid_id" class="form-control" required>
                <option v-for="tpoid in tpoids" v-bind:value="tpoid._id">
                  {{ tpoid.tid_nmbre }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label>Número de identificación (*):</label>
              <input type="number" class="form-control" v-model="entidad.ent_numero" required>
            </div>
            <div class="form-group">
              <label>Ciudad (*):</label>
              <input type="text" class="form-control" v-model="entidad.ent_ciudad" required>
            </div>
            <div class="form-group">
              <label>Dirección:</label>
              <input type="text" class="form-control" v-model="entidad.ent_direccion">
            </div>
            <div class="form-group">
              <label>Teléfono (*):</label>
              <input type="text" class="form-control" v-model="entidad.ent_telefono" required>
            </div>
            <div class="form-group">
              <label>Correo electrónico (*):</label>
              <input type="email" class="form-control" v-model="entidad.ent_email" required>
            </div>
            <br />
            <div v-if="message">
              <p class="alert alert-warning" role="alert">{{ message }}</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Guardar</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>

export default {
  props: ['add', 'id', 'entidad'],
  data() {
    return {
      title: "",
      item: {},
      message: "",
      tpoids: []
    }
  },
  created: function(){
    this.fetchTpoid();

    if (this.add) {
      this.title = "Crear una Entidad";
    } else {
      this.title = "Editar una Entidad";
      this.getItem(this.id);
    }
  },
  mounted: function() {

  },
  methods: {
    fetchTpoid() {
      let uri = "/tpoids/" + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.tpoids = response.data;
      });
    },
    saveItem() {
      if (this.add) {
        this.entidad.cmp_id = String(this.$store.state.company);
        let uri = "/entidades/add";
        //alert(this.entidad);
        this.axios
          .post(uri, this.entidad)
          .then((response) => {
            this.$emit('add-entidad', response.data);
            $('#entidadModal').modal('hide');
            this.message = "";
          })
          .catch((err) => {
            this.message = err.response.data.message;
          });
      } else {
        let uri = "/entidades/update/" + this.entidad._id;
        this.axios
        .post(uri, this.entidad)
        .then((response) => {
          this.$emit('add-entidad', response.data)
        })
        .catch((err) => {
          this.message = "¡Error al editar el registro! " + err;
        });
      }
    },
    getItem(id) {
      let uri = "/entidades/edit/" + id;
      this.axios.get(uri).then((response) => {
        this.entidad = response.data;
      });
    }
  }
}
</script>

<style>
/*
.modal-dialog {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width:500px;
    height:300px;
}
*/
.bigModal {
  width: 21cm;
}

</style>