<template>
  <!-- Modal -->
  <div class="modal" id="selloModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <form>
      <div class="modal-dialog" role="document">
        <div class="modal-content bigModal">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Sello de radicación</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="">
              <label class="col-md-4" for="top">Margen superior:</label>
              <input class="col-md-2" type="number" id="top" v-model:value="mtop">
            </div>
            <div class="">
              <label class="col-md-4" for="left">Margen izquierdo:</label>
              <input class="col-md-2" type="number" id="left" v-model:value="mleft">
            </div>
            <hr>
            <div id="sello">
              <div v-bind:style="`margin-top:${mtop}cm;margin-left:${mleft}cm;`">
                <div id="sello_square" style="padding:0 0 16px 0;width:6.4cm">
                  <div class="sellocss">
                    <div style="display:block;" align="center">
                      <img v-bind:src="$store.state.logo" style="height:60px;max-width:100%;max-height:100%;"/>
                    </div>
                    <div style="margin-left:0px">
                      <!--
                      <div style="display:block;">{{ sello.cmp_nmbre }}</div>
                      -->
                      <div style="display:block;">{{ sello.tcr_nmbre }}</div>
                      <div style="display:block;">Número: {{ sello.tcr_cdgo }}-{{ sello.dcm_ano }}-{{ sello.dcm_cdgo }}</div>
                    </div>
                    <div style="display:block">Fecha: {{ sello.dcm_fcharad }}</div>
                    <div style="display:block">Dependencia: {{ sello.dep_nmbre }}</div>
                    <div style="display:block">Serie: {{ sello.ser_nmbre }}</div>
                    <div style="display:block">Anexos: {{ sello.dcm_anexos }}</div>
                    <div v-if="sello.dep_tramite_nmbre" style="display:block">Tramitado por: {{ sello.dep_tramite_nmbre }}</div>
                    <div style="display:block">No. Folios: {{ sello.dcm_nflios }}</div>
                    <div v-if="sello.recibido" style="display:block" align="center"><span class="recibido">{{ sello.recibido }}</span></div>
                    <div style="display:block" align="right" class="siged">siged</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <button v-if="!isAntiqueChromeBrowser" type="button" class="btn btn-primary" id="copy_sello" v-on:click="copySello('#sello_square')" v-on:mouseout="removeSelloTooltip()"><i v-if="!copying" class="fa fa-clipboard"></i><i v-if="copying" class="fas fa-spinner"></i> Copiar</button>
            <button type="button" class="btn btn-primary" data-dismiss="modal" v-on:click="printSello('sello')">Imprimir</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { formatDate } from './../../plugins/filters';
import printJS from 'print-js';
import html2canvas from 'html2canvas';
import Bowser from "bowser";

export default {
  props: ['sello'],
  data() {
    return {
      mleft: 13,
      mtop: 0,
      compania: {},
      devicePixelRatio: null,
      copying: false,
      isAntiqueChromeBrowser: false
    }
  },
  filters: {
    formatDate
  },
  computed: {
    marginStyle(){
      return "'margin-top:" + mtop + "cm;margin-left:" + mleft + "cm'";
    }
  },
  created: function(){
    const browser = Bowser.getParser(window.navigator.userAgent);
    this.isAntiqueChromeBrowser = browser.satisfies({
      chrome: "<66"
    });

  },
  mounted: function() {

  },
  methods: {
    printSello(){
      setTimeout(() => {
        printJS({ printable: 'sello', type: 'html', targetStyles: ['*'], font_size: '', css: '/css/sello.css' });
      }, 1000);
    },
    printSello2(object){
      var report = window.document.getElementById(object);
      var p = window.open('', 'Imprimir');
      //console.log(report.innerHTML);
      p.document.write(
                "<!DOCTYPE html>"+
                "<html>"+
                "<head>"+
                "<link rel='stylesheet' href='/bootstrap/css/bootstrap.min.css'></link>"+
                "<link rel='stylesheet' href='/css/sello.css'></link>"+
                "</head>"+
                "<body>"+
                    report.innerHTML+
                "</body>"+
                "</html>");
      p.document.close();

      setTimeout(function(){ //giving it 200 milliseconds time to load
              p.focus();
              p.print();
              p.close();
      }, 2000);
    },
    copySello(obj){
      this.copying = true;
      this.devicePixelRatio = 1.25;

      html2canvas(document.querySelector(obj), { 
        allowTaint: true,
        scale: this.devicePixelRatio       
      }).then(canvas => { 
        canvas.toBlob(blob => { 
          navigator.clipboard.write([new ClipboardItem({'image/png': blob})])
          this.copying = false;
          $('#copy_sello').tooltip({ title: 'Sello copiado!' });
          $('#copy_sello').tooltip('show');        
        });
      });
    },
    removeSelloTooltip(){
      $('#copy_sello').tooltip('dispose');
    }
  }
}
</script>

<style>
/*
.modal-dialog {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width:500px;
    height:300px;
}
*/
.bigModal {
  width: 21cm;
}

.sellocss {
  width:6cm;
  padding:8px 8px 8px 8px;
  border:1px solid #000000;
  border-radius:10px;
  font:10px arial;
}

.recibido {
  font-style: italic;
  font: 8px arial;
}

.siged {
  font:6px arial;
}
</style>
