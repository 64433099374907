<template>
  <!-- Modal -->
  <div class="modal" id="tipoDocModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <form>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Agregar documento</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label"># (*):</label>
                  <input type="number" class="form-control col-md-8" v-model="tipodoc.hjc_nmro" required autofocus>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Fecha (*):</label>
                  <input type="date" class="form-control col-md-8" v-model="tipodoc.hjc_fecha" required>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Descripción del documento (*):</label>
                  <input type="text" class="form-control col-md-8" v-model="tipodoc.hjc_descripcion_doc" required>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Folio inicial (*):</label>
                  <input type="number" class="form-control col-md-8" v-model="tipodoc.hjc_folio_ini" required>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Folio final (*):</label>
                  <input type="number" class="form-control col-md-8" v-model="tipodoc.hjc_folio_fin" required>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Área responsable (*):</label>
                  <input type="text" class="form-control col-md-8" v-model="tipodoc.hjc_area_responsable" required>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <button type="submit" class="btn btn-primary" data-dismiss="modal" v-on:click="$emit('add-tipodoc', tipodoc)">Aceptar</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
//import { focus } from 'vue-focus';

export default {
  //directives: { focus: focus },
  props: ['tipodoc'],
  data() {
    return {
      focused: false
    }
  },
  created: function(){

  },
  mounted: function() {
    //this.setFocus();
  },
  methods: {
    setFocus: function() {
      //this.$refs.tid_id.focus();
    }
  }
}
</script>

<style>
/*
.modal-dialog {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width:500px;
    height:300px;
}
*/
</style>