<template>
  <!-- Modal -->
  <div class="modal" id="fileModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <form>
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Crear archivo desde una plantilla</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label><b>Seleccionar plantilla (*):</b></label>
              <select v-model="file.id" v-on:change="setPlantilla" class="form-control">
                <option v-for="i in plantillas" v-bind:value="i._id">
                  {{ i.pln_nmbre + ' (' + i.pln_cdgo + ')' }}
                </option>
              </select>
            </div>
            <!--
            <div class="form-group">
              <label>Encabezado:</label>
              <vue-editor id="encabezado" class="editor1" useCustomImageHandler @image-added="handleImageAdded" v-model="file.encabezado" :editorToolbar="customToolbar"></vue-editor>
            </div>
            -->
            <div class="form-group">
              <label><b>Contenido:</b></label>
              <vue-editor id="contenido" class="content_editor" useCustomImageHandler @image-added="handleImageAdded" v-model="file.contenido" :editorToolbar="customToolbar"></vue-editor>
            </div>
            <!--
            <div class="form-group">
              <label>Pie de página:</label>
              <vue-editor id="piepagina" class="editor1" useCustomImageHandler @image-added="handleImageAdded" v-model="file.piepagina" :editorToolbar="customToolbar"></vue-editor>
            </div>
            -->
            <div class="form-group">
              <label><b>Tamaño de papel:</b></label>
              <div class="text-left">
                <div class="form-check">
                  <input class="form-check-input" type="radio" id="tipo1" v-model="file.papel" value="1" checked>
                  <label class="form-check-label" for="tipo1">Carta</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" id="tipo2" v-model="file.papel" value="2">
                  <label class="form-check-label" for="tipo2">Oficio</label>
                </div>
              </div>
            </div>            
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <button type="submit" class="btn btn-primary" v-on:click.prevent="createClick">Crear</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { VueEditor, Quill } from "vue2-editor";

export default {
  props: ['file', 'plantillas'],
  components: {
    VueEditor
  },
  data() {
    return {
      plantillaSeleccionada: {},
      customToolbar : [['bold', 'italic', 'underline', 'strike'],[{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],[{ 'script': 'sub'}, { 'script': 'super' }],[{ 'indent': '-1'}, { 'indent': '+1' }],[{ 'color': [] }, { 'background': [] }],
        ['link', 'image']]
    }
  },
  created: function(){

  },
  mounted: function() {

  },
  methods: {
    // Revisar sección para detectar las imágenes insertadas 
    reviewSection(section, images) {
      var x = 0;
      var y = 0;

      if (!section) return;
      
      while (-1 < x &&  x < section.length){
        var imgPath = '<img src="./../../temp/' + this.$store.state.company + '/templates/images/';
        x = section.indexOf(imgPath, x);
        
        if (x > -1){
          y = section.indexOf('">', x);
          x = x + imgPath.length;
          var file = section.substring(x, y);
          images.push(file);            
        }
      }
    },    
    setPlantilla(e){
      const downloadImages = () => {
        var images = [];
        
        this.reviewSection(this.plantillaSeleccionada.pln_encabezado, images);
        this.reviewSection(this.plantillaSeleccionada.pln_contenido, images);
        this.reviewSection(this.plantillaSeleccionada.pln_piepagina, images);
        this.reviewSection(this.plantillaSeleccionada.pln_marcaagua, images);
        this.reviewSection(this.plantillaSeleccionada.pln_imagenfondo, images);

        if (images.length > 0){
          let uri = "/api/saveimages";
          this.axios
            .post(uri, { cmp_id: this.$store.state.company, images: images, upload: false })
            .then((response) => {
              console.log(response);

              this.$set(this.file, 'encabezado', this.plantillaSeleccionada.pln_encabezado);
              this.$set(this.file, 'contenido', this.plantillaSeleccionada.pln_contenido);
              this.$set(this.file, 'piepagina', this.plantillaSeleccionada.pln_piepagina);
              this.$set(this.file, 'marcaagua', this.plantillaSeleccionada.pln_marcaagua);
              this.$set(this.file, 'imagenfondo', this.plantillaSeleccionada.pln_imagenfondo);
              this.$set(this.file, 'tipo', this.plantillaSeleccionada.pln_tipo);
              this.$set(this.file, 'fuente', this.plantillaSeleccionada.pln_fuente);
              this.$set(this.file, 'tamano', this.plantillaSeleccionada.pln_tamano);

              this.message = "Imágenes descargadas";
            })
            .catch((err) => {
              console.log(err);
              this.message = "¡Error al descargar las imágenes! " + err;
            });
        } else {
          this.$set(this.file, 'encabezado', this.plantillaSeleccionada.pln_encabezado);
          this.$set(this.file, 'contenido', this.plantillaSeleccionada.pln_contenido);
          this.$set(this.file, 'piepagina', this.plantillaSeleccionada.pln_piepagina);
          this.$set(this.file, 'marcaagua', this.plantillaSeleccionada.pln_marcaagua);
          this.$set(this.file, 'imagenfondo', this.plantillaSeleccionada.pln_imagenfondo);
          this.$set(this.file, 'tipo', this.plantillaSeleccionada.pln_tipo);
          this.$set(this.file, 'fuente', this.plantillaSeleccionada.pln_fuente);
          this.$set(this.file, 'tamano', this.plantillaSeleccionada.pln_tamano);
        }
      }

      var id = e.target.value;

      for (var i = 0; i < this.plantillas.length; i++){
        if (id == this.plantillas[i]._id){
          this.file.name = this.plantillas[i].pln_cdgo + '.pdf';
          this.plantillaSeleccionada = this.plantillas[i];
          downloadImages();
          i = this.plantillas.length;
        }
      }      
    },
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      var data = new FormData();
      data.append("file", file);
      data.append("name", file.name);
      data.append("host", window.location.origin);
      data.append("cmp_id", this.$store.state.company);

      this.axios.post("/api/images", 
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }        
      )
        .then(result => {
          let url = result.data.url; // Get url from response
          Editor.insertEmbed(cursorLocation, "image", url);
          resetUploader();
        })
        .catch(err => {
          console.log(err);
        });
    },
    createClick(){
      if (!this.file.id){
        alert('Seleccione la plantilla para generar el archivo adjunto.');
        return;
      } else {
        this.$emit('add-file', this.file);
        $('#fileModal').modal('hide');
      }
    }    
  }
}
</script>

<style>
/*
.modal-dialog {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width:500px;
    height:300px;
}
*/

  .editor1 .ql-editor { min-height:100px }
  .editor2 .ql-editor { min-height:140px }
  .content_editor .ql-editor { height: 30vh }
</style>
